import request from '@/utils/request'

export function fetchList(query) {
    return request({
        url: '/admin/logisticsCost/list',
        method: 'get',
        params: query
    })
}


export function saveOrUpdate(data) {
    return request({
        url: '/admin/logisticsCost/saveOrUpdate',
        method: 'post',
        data
    })
}

export function deleteData(id) {
  return request({
    url: '/admin/logisticsCost/delete',
    method: 'get',
    params: { id }
  })
}

export function getById(id) {
  return request({
    url: '/admin/logisticsCost/getById',
    method: 'get',
    params: { id }
  })
}

export function audit(data) {
  return request({
    url: '/admin/logisticsCost/audit',
    method: 'post',
    data
  })
}
