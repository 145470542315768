<template>
  <div class="wrapper home-page logistics-cost">
    <div class="logistics-cost-top">
      <el-button :disabled="!common.canEdit()" type="primary" class="import-btn" @click="addClick">添加物流费用
      </el-button>
      <div class="right">
        <el-button type="primary" @click="exportExcel" class="export-btn">导出Excel表格</el-button>
      </div>
    </div>
    <!-- 切换按钮 -->
    <el-tabs class="main-tabs" v-model="activeName" @tab-click="tabClick">
      <el-tab-pane v-for="(item,index) in tabList" :key="index" :label="item.label" :name="item.name">
        <!-- <dataTable :tableData="tableData" :activeName="activeName" v-if="activeName === index.toString()"></dataTable> -->
        <el-table class="main-table" :data="tableData" ref="multipleTable" :key="activeName" border
                  header-row-class-name="table-header" highlight-current-row height="535px" v-loading="loading">
          <el-table-column type="selection" width="40" v-if="activeName == 1"></el-table-column>
          <el-table-column label="序号" type="index" align="center" width="80"></el-table-column>
          <el-table-column label="地区" prop="region" align="center"></el-table-column>
          <el-table-column prop="info" label="车辆信息" align="center">
            <template slot-scope="{row}">
              <el-select v-model="row.logisticsCarId" :disabled="checkState(row)" placeholder="请选择车辆信息">
                <el-option v-for="item in carOptions" :key="item.id" :label="item.plateNo"
                           :value="item.id">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="consignorId" label="发货员" align="center">
            <template slot-scope="{row}">
              <el-select v-model="row.consignorId" :disabled="checkState(row)" placeholder="请选择发货员">
                <el-option v-for="item in consignorOptions" :key="item.id" :label="item.name"
                           :value="item.id">
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="trackingNumber" label="物流单号" align="center">
            <template slot-scope="{row}">
              <el-input v-model="row.trackingNumber" :readonly="checkState(row)"
                        placeholder="请输入物流单号">
              </el-input>
            </template>
          </el-table-column>
          <el-table-column label="整车照片" align="center">
            <template slot-scope="{row}">
              <el-image v-if="row.state == 1 || row.state == 2 && row.carImgs != null"
                        style="width:23px; height:23px" :src="row.carImg"
                        :preview-src-list="previewSrcList(row.carImgs)">
              </el-image>
              <el-button type="primary" size="mini" v-if="row.state == -1 || row.state == 3" round
                         @click="vehicleUpload(row)">点击上传
              </el-button>
            </template>
          </el-table-column>
          <el-table-column prop="inputDate" label="录入日期" align="center" width="250px">
            <template slot-scope="{row}">
              <el-date-picker :readonly="checkState(row)" v-model="row.inputDate" type="date" value-format="yyyy-MM-dd"
                              placeholder="清选择录入日期">
              </el-date-picker>
            </template>
          </el-table-column>
          <el-table-column prop="price" label="费用（元）" align="center">
            <template slot-scope="{row}">
              <el-input v-model="row.price" :readonly="checkState(row)" type="number"
                        placeholder="请输入费用（元）">
              </el-input>
            </template>
          </el-table-column>
          <el-table-column label="物流单照片" align="center">
            <template slot-scope="{row}">
              <el-image v-if="row.state == 1 || row.state == 2" style="width:23px; height:23px"
                        :src="row.imgUrl" :preview-src-list="row.imgList.map(e => e.url)">
              </el-image>
              <el-button v-if="row.state == -1 || row.state == 3" type="primary" size="mini" round
                         @click="imgUpload(row)">
                <!-- <div v-if="row.state == 1 || row.state == 2">查看物流单</div> -->
                <div>点击上传</div>
              </el-button>
            </template>
          </el-table-column>
          <el-table-column label="状态" align="center">
            <template slot-scope="{row}">
              <el-tag v-if="row.state == -1">未提交</el-tag>
              <el-tag v-if="row.state == 1" type="warning">待审核</el-tag>
              <el-tag v-if="row.state == 2" type="success">已通过</el-tag>
              <el-tag v-if="row.state == 3" type="danger">未通过</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="不通过原因" prop="notPassReason" align="center"></el-table-column>
          <el-table-column prop="remark" label="备注" align="center">
            <template slot-scope="{row}">
              <el-input v-model="row.remark" :readonly="checkState(row)"
                        placeholder="备注">
              </el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="300" align="center" class-name="table-operate">
            <template slot-scope="{row, $index}">
              <el-button type="primary" size="mini" round @click="submitClick(row)"
                         v-if="row.state== -1 || row.state == 3">
                提交审核
              </el-button>
              <el-button type="primary" size="mini" round @click="revoke(row)"
                         v-if="row.state== 1 && common.canEdit()">
                撤回
              </el-button>
              <el-button type="warning" size="mini" round @click="examineClick(row)"
                         v-if="row.state== 1 && common.canAudit()">
                审核
              </el-button>
              <el-button type="warning" size="mini" round @click="reverseAudit(row)"
                         v-if="row.state== 2 && common.canAudit()">
                反审核
              </el-button>
              <el-button type="danger" v-if="row.state == -1" size="mini" round
                         @click="deleteClick(row,$index)">删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>

    <div class="bottom" v-if="activeName==1 && this.common.canAudit()">
      <el-button class="examine-btn" type="primary" size="small" round @click="oneExamine()">一键审核通过</el-button>
    </div>
    <el-pagination background layout="prev, pager, next,total,jumper" :total="total" :current-page.sync="query.page"
                   :page-size="query.pagesize" @current-change="getList"></el-pagination>
    <!-- 上传照片弹窗 -->
    <el-dialog title="物流单照片" :visible.sync="dialogFormVisible">
      <el-form class="upload-img" label-width="80px">
        <el-form-item label="上传照片:">
          <el-button type="primary" @click="getVideo">打开摄像头</el-button>
          <el-button type="primary" @click="getImage">拍照</el-button>
          <!--<el-button type="warning" @click="closeVideo">关闭摄像头</el-button>-->
          <div class="photograph">
            <video id="video"></video>
            <canvas id="canvas"></canvas>
          </div>
          <div class="img-list">
            <div class="img-list-item" v-for="(url,index) in imgUrlList" :key="url">
              <el-image :src="url" :preview-src-list="imgUrlList"></el-image>
              <i class="el-icon-error" @click="deleteImg(index)"></i>
            </div>
          </div>
          <img id="img"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm()">确 定</el-button>
          <el-button @click="dialogFormVisible = false">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 上传整车照片 -->
    <el-dialog title="整车照片" :visible.sync="dialogVehicleVisible">
      <el-form class="upload-img" label-width="80px">
        <el-form-item label="上传照片:">
          <el-upload :action="action" list-type="picture-card" :on-preview="handlePictureCardPreview"
                     :on-success="handlePictureCardSuccess"
                     :file-list="fileList" :headers="headers" :on-remove="handleRemove">

            <i class="el-icon-plus"></i>
          </el-upload>
        </el-form-item>
        <el-form-item style="margin-top: 20px;">
          <el-button type="primary" @click="vehicleForm()">确 定</el-button>
          <el-button @click="dialogVehicleVisible = false">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 照片预览 -->
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
    <el-dialog title="导出数据" :visible.sync="dialogExportVisible" :close-on-click-modal="false">
      <el-form ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="选择日期范围">
          <el-date-picker v-model="dateRange" type="datetimerange" range-separator="至"
                          start-placeholder="开始日期" end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="downloadLoading" @click="handleDownload">下载</el-button>
          <el-button @click="dialogExportVisible = false">取 消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
  <!-- 导出弹窗 -->


</template>

<script>
import {
  fetchList,
  deleteData,
  audit,
  getById,
  saveOrUpdate
} from "@/api/logisticsCost";
import {
  v4 as uuidv4
} from 'uuid';
import {
  upload
} from "@/api/invoice"
import {
  selectList as selectConsignorList
} from "@/api/consignor"
import {
  selectList as selectLogisticsCarList
} from "@/api/logisticsCar"

export default {
  components: {
    // dataTable
  },
  data() {
    return {
      loading: true,
      headers: {
        token: ''
      },
      carOptions: [],
      dateRange: null,
      consignorOptions: [],
      action: '/api/admin/upload',
      dialogVehicleVisible: false,
      dialogFormVisible: false,
      dialogImageUrl: [],
      dialogVisible: false,
      dialogExportVisible: false,
      downloadLoading: false,
      imgUrlList: [],
      tabList: [{
        name: "0",
        label: "全部"
      }, {
        name: "1",
        label: "待审核"
      }, {
        name: "2",
        label: "已通过"
      }, {
        name: "3",
        label: "未通过"
      }],
      activeName: '0',
      tableData: [],
      fileList: [],
      activeRow: null,
      query: {
        status: 0,
        page: 1,
        pagesize: 10,
        sort: '-input_date',
      },
      total: 0,
      activeImglist: [],
      activeImg: ''
    }
  },
  mounted() {
    this.getList()
    this.getConsignorList()
    this.getLogisticsCarList()
    this.headers.token = localStorage.getItem('token')
  },
  methods: {
    previewSrcList(carImgs) {
      let arr = []
      if (carImgs == null) {
        arr = []
      } else {
        arr = carImgs.map(e => e.url)
      }
      return arr
    },
    handleDownload() {
      const loading = this.$loading({
        lock: true,
        text: '正在导出Excel表格，请耐心等待！',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      this.downloadLoading = true
      this.query.pagesize = 100000
      this.query.page = 1
      if (this.dateRange != null) {
        this.query.startDate = this.dateRange[0]
        this.query.endDate = this.dateRange[1]
      }

      fetchList(this.query).then(response => {
        import('@/vendor/Export2Excel').then(excel => {
          let list = response.data.list
          const tHeader = ['地区', '车牌号码', '发货人', '录入日期', '费用', '状态', '不通过原因']
          const filterVal = ['region', 'logisticsCarPlateNo', 'consignorName', 'inputDate', 'price',
            'state', 'notPassReason'
          ]
          const data = this.formatJson(filterVal, list)
          excel.export_json_to_excel({
            header: tHeader,
            data,
            filename: '第三方物流明细单',
            autoWidth: true,
            bookType: 'xlsx'
          })
          this.downloadLoading = false
          loading.close();
          this.dialogExportVisible = false
        })
      })
    },
    exportExcel() {
      this.dialogExportVisible = true
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        if (j === "state") {
          let text = ''
          switch (v[j]) {
            case -1:
              text = '未提交'
              break
            case 1:
              text = '待审核'
              break
            case 2:
              text = '已通过'
              break
            case 3:
              text = '未通过'
              break
          }
          return text
        }
        return v[j]
      }))
    },
    getLogisticsCarList() {
      selectLogisticsCarList().then(res => {
        this.carOptions = res.data
      })

    },
    handlePictureCardSuccess(res, file, fileList) {
      this.updateImages(fileList)
    },
    handleRemove(file, fileList) {
      this.updateImages(fileList)
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    updateImages(fileList) {
      this.activeRow.carImgs = []
      for (var i = 0; i < fileList.length; i++) {
        if (fileList[i].response) {
          this.activeRow.carImgs = this.activeRow.carImgs.concat([{
            name: fileList[i].response.data.name,
            url: fileList[i].response.data.url
          }])
        } else {
          this.activeRow.carImgs = this.activeRow.carImgs.concat([{
            name: fileList[i].name,
            url: fileList[i].url
          }])
        }
      }
      this.fileList = this.activeRow.carImgs

    },
    vehicleForm() {
      this.dialogVehicleVisible = false
    },
    vehicleUpload(row) {
      this.activeRow = row
      this.fileList = this.activeRow.carImgs
      this.dialogVehicleVisible = true
    },
    getConsignorList() {
      selectConsignorList().then(res => {
        this.consignorOptions = res.data
      })
    },
    getList() {
      this.loading = true
      fetchList(this.query).then(res => {
        if (res.code === 0) {
          this.tableData = res.data.list
          this.total = res.data.total
          this.loading = false
        }
      })
    },
    checkState(row) {
      if (row.state == 2 || row.state == 1) {
        return true
      } else {
        return false
      }
    },

    oneExamine() {
      let examineData = this.$refs.multipleTable[1].selection
      let ids = []
      for (let i in examineData) {
        ids.push(examineData[i].id)

      }
      if (ids.length <= 0) {
        this.$message({
          type: "error",
          message: "请选择要审核的数据!",
        });
        return
      }
      let auditPost = {
        ids: ids,
        status: 2,
        notPassReason: ''
      }

      this.$confirm("确定要全部审核通过吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            audit(auditPost).then(res => {
              if (res.code === 0) {
                this.$message({
                  type: "success",
                  message: "审核通过!",
                });
                this.getList()
              }
            })
          })
          .catch(() => {
          });
    },
    submitForm() {
      this.dialogFormVisible = false
    },
    dataURLtoFile(dataurl, filename) { //将base64转换为文件
      var arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {
        type: mime
      });
    },
    revoke(row) {
      row.state = -1
      saveOrUpdate(row).then(res => {
        if (res.code == 0) {
          this.$message({
            type: "success",
            message: "已撤回!",
          });
        }
      })
    },
    reverseAudit(row) {
      this.$confirm("确定要反审核该条数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {

            row.state = 1
            this.commitExamin(row)


          })
          .catch(() => {
          });

    },
    examineClick(row) {
      getById(row.id).then(res => {
        if (res.code === 0) {
          if (res.data.state === -1) {
            this.$message({
              type: "error",
              message: "已被用户撤回!",
            });
            this.getList()
          }
        }
      })
      this.$confirm("请确定审核是否通过?", "提示", {
        confirmButtonText: "通过",
        cancelButtonText: "不通过",
        type: "warning",
      })
          .then(() => {
            row.state = 2
            row.notPassReason = null
            saveOrUpdate(row).then(res => {
              if (res.code == 0) {
                this.$message({
                  type: "success",
                  message: "审核通过!",
                });
                this.getList()
              }
            })

          })
          .catch(() => {
            this.$prompt('请输入不通过原因', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              inputPattern: /\S/,
              inputErrorMessage: '请输入不通过原因'
            }).then(({
                       value
                     }) => {
              row.state = 3
              row.notPassReason = value
              saveOrUpdate(row).then(res => {
                if (res.code == 0) {
                  this.$message({
                    type: "success",
                    message: "提交成功!",
                  });
                  this.getList()
                }
              })
            }).catch(() => {

            });
          });
    },
    deleteImg(index) {
      this.imgUrlList.splice(index, 1)
      this.activeRow.imgList.splice(index, 1)
    },
    imgUpload(item) {

      this.activeRow = item
      this.dialogFormVisible = true

      if (this.activeRow.imgList === null) {
        this.activeRow.imgList = []
        this.imgUrlList = []
      } else {
        this.imgUrlList = this.activeRow.imgList.map(e => e.url)
      }


    },
    getVideo() {
      var video = document.getElementById("video");
      const loading = this.$loading({
        lock: true,
        text: '正在加载摄像头...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.5)',
        customClass: "main-loading"
      });
      navigator.mediaDevices.getUserMedia({
        video: true
      }).then(function (stream) {
        video.srcObject = stream;
        video.play();
        loading.close();
      }).catch(function (err) {
        console.log(err);
      });
    },
    //对video进行截图
    getImage() {
      var video = document.getElementById("video");
      var width = video.clientWidth;
      var height = video.clientHeight;
      var canvas = document.getElementById("canvas");
      canvas.height = height
      canvas.width = width
      var context = canvas.getContext("2d");
      context.drawImage(video, 0, 0, width, height);
      var imgData = canvas.toDataURL("image/png");
      // var img = document.getElementById("img");
      // img.src = imgData;
      let fileName = uuidv4()
      const file = this.dataURLtoFile(imgData, fileName + ".png")
      let form = new FormData();
      form.append('file', file);
      upload(form).then(res => {
        if (res.code === 0) {
          this.imgUrlList.push(res.data.url)
          this.activeRow.imgList.push(res.data)
        }
      })
    },
    tabClick() {
      this.query.status = this.activeName
      this.getList()
    },
    addClick() {
      this.activeName = "0"
      this.tableData.unshift({
        inputDate: '',
        price: "",
        state: -1,
        imgList: [],
        info: '',
        consignorId: '',
        logisticsCarId: '',
        carImgs: []
      })
    },
    submitClick(row) {
      this.$confirm("确定要提交审核吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {

            if (row.imgList.length == 0 || row.inputDate == "" || row.price ==
                "" || row.logisticsCarId == 0 || row.consignorId == 0) {
              this.$message({
                type: "error",
                message: "请完善信息!",
              });
              return
            }
            if (row.id !== undefined) {
              getById(row.id).then(res => {
                if (res.code == 0) {
                  if (Object.entries(res.data).toString() === Object.entries(row)
                      .toString()) {
                    this.$confirm("物流单信息未修改请确认是否提交审核?", "提示", {
                      confirmButtonText: "确定",
                      cancelButtonText: "取消",
                      type: "warning",
                    })
                        .then(() => {
                          this.commitExamin(row)
                        })
                  } else {
                    this.commitExamin(row)
                  }
                }
              })
            } else {
              this.commitExamin(row)
            }


          })
          .catch(() => {
          });
    },
    commitExamin(row) {
      row.state = 1
      saveOrUpdate(row).then(res => {
        if (res.code === 0) {
          this.$message({
            type: "success",
            message: "提交成功!",
          });
          this.getList()
        }
      })
    },
    deleteClick(row, index) {
      this.$confirm("确定要永久删除该行数据吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
          .then(() => {
            this.tableData.splice(index, 1)
            if (row.id) {
              deleteData(row.id).then(res => {
                if (res.code === 0) {
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                  this.getList()
                }

              })
            }

          })
          .catch(() => {
          });
    },
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/homepage.scss";

.el-select-dropdown__item {
  padding: 0 20px;
}

.logistics-cost {
  .logistics-cost-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 20px 0;

    .import-btn {
      font-size: 24px;
      background-image: linear-gradient(to right, #3f9dff, #017eff, #3f9dff);
      padding: 15px 40px;
      box-shadow: 5px 5px 3px #c6d8fe;
    }
  }
}

.bottom {
  background-color: rgba($color: $main-color, $alpha: 0.1);
  padding: 5px 10px;
  text-align: right;

  .examine-btn {
    border-radius: 5px;
    font-size: 16px;
  }
}
</style>
